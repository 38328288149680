import React from "react";
//import { Link } from 'react-router-dom';
import "./Signup_form.css";	
import login from '../images/login.png'
import user from '../images/user.png'
import email from '../images/email.png'
import eye from '../images/eye.png' 
import axios from 'axios';

export default class SignupForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			password: '',
			email : '',
			errorlogin :  ""
		};
	}
	
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		//console.log(this.state);
	}
	proceedToSignup(){
		var self = this;
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_registration', {
			name: this.state.name,
			password: this.state.password,
			email: this.state.email,
			
		})
		.then(function (res) {
			//console.log("signup API Response");
			console.log(res);
			if( res.status === 200 && res.data.success === 1  ) {
				localStorage.setItem('name', self.state.name);
				localStorage.setItem('userid', res.data.data.user_id);
				localStorage.setItem('_token', res.data.data.login_token);
				self.setState({ errorlogin : "" });
				window.location.href="/category";
			} else {
				self.setState({ errorlogin : res.data.message });
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	backToLogin(){
		window.location.href="/";	
	}

	render(){
		return(
			<section className="App-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="logo-image">
							<h6>Poster <br/> Maker</h6>
						</div>
					</div>
				</div>
				<div className="row column-reverse">	
					<div className="col-md-6"> 
						<div className="log-sign_inner">

				<div className="login-form">
					<h1> Design Anything Publish anywhere</h1>
					<p> Create an account, it’s free. Logo is loved by beginners and expects, terms and individuals</p>
					{this.state.errorlogin !== "" ? (
						<div class="alert alert-danger"> 
						
						<h6>{ this.state.errorlogin}</h6>
						
						</div>
						) : (
						""
					)}

					<span className="Login_user">
						
						<input type="text" name="name" value={this.state.name} className="input-text" placeholder="Enter your user name" onChange={ this.handleChange.bind(this) }/>
						<img src={user} className="user_img" alt=""/>
					</span>
					<span className="Login_user">
						
						<input type="email" name="email" value={this.state.email} className="input-text" placeholder="Enter your user email" onChange={ this.handleChange.bind(this) }/>
						<img src={email} className="email_img" alt=""/>
					</span>
					<span className="Login_user">
					<input type="Password" name="password" value={this.state.password} className="input-pass" placeholder="Enter your password" onChange={ this.handleChange.bind(this) } />
					<img src={eye} className="eye_img" alt=""/>
					</span>
					
					<div className="sign_butns">
					<button className="signup_btn" onClick={this.proceedToSignup.bind(this)} >Signup</button>
					<button className="login_btn" onClick={this.backToLogin.bind(this)} >Back to Login</button>
					{/*<Link className="signup_btn" to="/category" >Signup</Link>
					<Link className="login_btn" to="/home" >Back to Login</Link>*/}
					</div>
				</div>
				</div>
					</div>
					<div className="col-md-6">
						<div className="Log-sign_image">
							<img src={login} alt=""/>
						</div>
					</div>
				</div>
			</div>
		</section>

		);
	}
	
	
}