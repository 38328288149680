import React from "react";
//import ReactDom from "react-dom";



import "./main.css";
import "./dashboard.css";
//import "./assets/scripts/main.js";
import toggle_button from '../images/toggle_button.png'
import { Link } from 'react-router-dom';

import settings1 from '../images/settings1.png'
import $ from 'jquery';

export default class AppHeader extends React.Component {

	   constructor(props) {
        super(props);
        this.state = {};
    }
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	 
	render(){
		return(


        <div className="app-header header-shadow">
            <div className="app-header__logo sidebar">
            
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" onClick={() => this.toggleButton()} className="hamburger close-sidebar-btn hamburger--elastic" data-className="closed-sidebar">
                            <span className="hamburger-box">
                                {/*// <span className="hamburger-inner"></span>*/}
                                <img src={toggle_button} className="bli" alt=""/>
                            </span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                <span>
                    <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    
            <div className="app-header__content">
	            <div className="app-header-left">
	                   
	            </div>
	            <div className="app-header-right">
	                <div className="header-btn-lg pr-0">
	                    <div className="widget-content p-0">
	                        <div className="widget-content-wrapper">
	                            <div className="widget-content-left  mr-4 ">
	                            <span className="user_image">
	                            	
	                            </span>
	                            </div>    
	                            <div className="widget-content-left  mr-3 header-user-info">
	                                <div className="widget-heading">
	                                        Alina Mclourd
	                                </div>
	                                <div className="widget-subheading">
	                                        VP People Manager
	                                </div>
	                            </div>
	                            <div className="widget-content-left mr-4">
	                                <div className="btn-group">
	                                    <Link to="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
	                                            
	                                            <i className="fa fa-angle-down ml-2 opacity-8"></i>
	                                    </Link>
	                                    <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
	                                            <button type="button" tabindex="0" className="dropdown-item">User Account</button>
	                                            <button type="button" tabindex="0" className="dropdown-item">Settings</button>
	                                            <Link to=""><h6 tabindex="-1" className="dropdown-header">Log Out</h6></Link>
	                                            
	                                    </div>
	                                </div>
	                            </div>
	                            <div className="widget-content-right header-user-info ml-3 Setting_opt">
	                                <button type="button" className="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example">
	                                        <img src={settings1} alt=""/>
	                                </button>
	                            </div>
	                        </div>
	                    </div>
	                </div>  
	            </div>
	        </div>    
        </div>      
		
	



		);
	}
	
	
}
