import React from "react";
//import ReactDom from "react-dom";
//import Container from "react-bootstrap/Container";
//import Button from "react-bootstrap/Button";
import {Switch, Route } from 'react-router-dom';
import {PrivateRoute} from "./helpers/PrivateRoute"
//import  { Redirect } from 'react-router-dom'

import "./App.css";

import LoginForm from './LoginForm.js'
import SignupForm from './SignupForm.js'
import Category from './Category.js'
import Invitefriend from './Invitefriend.js'
import Getstarted from './Getstarted.js'
import Dashboard from './Dashboard.js'
import CreateDesign from './CreateDesign.js'
import Newdesign from './Newdesign.js'
import Demo from './Demo.js'
import Demo1 from './Demo1.js'
import Demo2 from './Demo2.js'
//import demo3 from './demo3.js'
export default class App extends React.Component {
	render(){
		return( 

			<Switch>
			// <Route exact path="/" component={LoginForm}/>
			// <Route exact path="/Signup" component={SignupForm}/>
   //   		<Route exact path="/category" component={Category}/>
   //   		<Route exact path="/Invitefriend" component={Invitefriend}/>
   //   		<Route exact path="/Getstarted" component={Getstarted}/>
            <Route path="/" component={Dashboard} />
     		<Route exact path="/dashboard" component={Dashboard} />
   //   		<PrivateRoute exact path="/CreateDesign" component={CreateDesign} />
   //   		<PrivateRoute exact path="/Newdesign" component={Newdesign} />
   //   		<Route exact path="/Demo" component={Demo}/>
   //   		<Route exact path="/Demo1" component={Demo1}/>
			// <Route exact path="/Demo2" component={Demo2}/>
			{/* //<Route exact path="/demo3" component={demo3}/>*/}
     		</Switch>
		);
	}
	
	
}
