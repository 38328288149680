import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom';
import App from "./components/App";
//import Category from "./components/Category";
//import CategoryList from "./CategoryList";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
//import '../node_modules/jquery/dist/jquery.min.js';
import "./index.css";	
import Dashboard from './components/Dashboard';



 


ReactDOM.render((
    <BrowserRouter>
    <Switch>
    <Route path="/" component={Dashboard} />
     		<Route exact path="/dashboard" component={Dashboard} />
     		</Switch>
     	
    </BrowserRouter>	
), document.getElementById('root')); 