import React from "react";
import "./main.css";
import "./newdesign.css";
import { Link } from 'react-router-dom';
import Alldesign from '../images/Alldesign.png'
import homeBlue from '../images/homeBlue.png'
import Templates from '../images/Templates.png'
import Photos from '../images/Photos.png'
import kit from '../images/kit.png'
import team from '../images/team.png'
import poster1 from '../images/poster1.png'
import settings1 from '../images/settings1.png'
import $ from 'jquery';

export default class Newdesign extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			user_name : ""
		};
	}
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	
	componentDidMount() {
		const user_name =sessionStorage.getItem('name');
		this.setState({ user_name : user_name });	
		
	}
	
	 
render(){
return(
<section className="dash_inner">
	<div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
        <div className="app-header header-shadow">
            
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        // <span className="hamburger-box">
                        //     <span className="hamburger-inner"></span>
                        // </span>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    <div className="app-header__content">
                <div className="app-header-left">
                   
                       </div>
                <div class="app-header-right">
                    <div class="header-btn-lg pr-0">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                
                                <div class="widget-content-left  mr-3 header-user-info">
                                    <div class="widget-heading">
										{ this.state.user_name }
                                    </div>
                                    <div className="widget-subheading">
                                        VP People Manager
                                    </div>
                                </div>
                                <div className="widget-content-left mr-4">
                                    <div className="btn-group">
                                        <Link to="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                                            <img width="42" className="rounded-circle" src="assets/images/avatars/1.jpg" alt=""/>
                                            <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                        </Link>
                                        <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                            <button type="button" tabindex="0" className="dropdown-item">User Account</button>
                                            <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                                            <h6 tabindex="-1" className="dropdown-header">Header</h6>
                                            <button type="button" tabindex="0" class="dropdown-item">Actions</button>
                                            <div tabindex="-1" className="dropdown-divider"></div>
                                            <button type="button" tabindex="0" class="dropdown-item">Dividers</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content-right header-user-info ml-3 Setting_opt">
                                    <button type="button" class="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example">
                                        <img src={settings1} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        </div>
            </div>
        </div>        
            <div className="app-main">
                <div className="app-sidebar sidebar-shadow sidebar left_sidebar">
                    <div className="app-header__logo">
                        <div className="logo-src"></div>
                        <div class="header__pane ml-auto">
                            <div>
                                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                                    <span class="hamburger-box">
                                        <span class="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="app-header__mobile-menu">
                        <div>
                            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <span>
                            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"></i>
                                </span>
                            </button>
                        </span>
                    </div>    
                    <div class="scrollbar-sidebar " id="sidebar">
						<div className="app-header__logo sidebar">
							<div className="header__pane ml-auto">
								<div>
									<button type="button" onClick={() => this.toggleButton()} className="hamburger close-sidebar-btn hamburger--elastic" data-className="closed-sidebar">
										<span className="hamburger-box">
											<span className="hamburger-inner"></span>
										</span>
									</button>
								</div>
							</div>
						</div>
                        <div class="app-sidebar__inner">
							  <div class="d-flex">
								<div class="tab_icons mb-3">
									<ul class="nav nav-pills flex-column" id="myTab" role="tablist">
									  <li class="nav-item">
										<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><i class="fa fa-th-large" aria-hidden="true"></i></a>
									  </li>
									  <li class="nav-item">
										<a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-user" aria-hidden="true"></i></a>
									  </li>
									  <li class="nav-item">
										<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false"><i class="fa fa-text-width" aria-hidden="true"></i></a>
									  </li>
									</ul>
								</div>
								
									<div class="tab_content pl-3 pr-3">
								  <div class="tab-content" id="myTabContent">
									<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
									<div class="grid_imgs d-flex flex-wrap">
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
									</div>
							  </div>
							  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
									<div class="grid_imgs d-flex flex-wrap">
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
									</div>
							  </div>
							  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
									<div class="grid_imgs d-flex flex-wrap">
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
										<div class="img_1 p-2">
											<img src={poster1} alt=""/>
										</div>
									</div>
							  
							  </div>
							</div>
								</div>
							</div>
							
                        </div>
                    </div>
                </div>    
                <div class="app-main__outer sidebar">
                	{/*// <div className="header_pane">
                    		
                    //  </div>*/}
                    <div className="app-main__inner">  
                    		 
                    		<div className="row">
                    			<div className="col-md-12">
                                    <div className="poster-Top-bar">
                                        <a href=""><i class="fa fa-sticky-note" aria-hidden="true"></i> </a> &nbsp;
                                         <a href=""><i class="fa fa-clone" aria-hidden="true"></i> </a> &nbsp;
                                         <a href=""><i class="fa fa-plus" aria-hidden="true"></i></a>
                                    </div>
                                    <div className="poster-box">

                                    </div>
                                    <button className="Poster-add-btn"> + Add a new page </button>
                                </div>
                    		</div>
                    </div>
                    
                </div>
        </div>
    </div>
</section>
		);
	}	
}