import React from "react";
//import ReactDom from "react-dom";
import "./Getstarted.css";
//import { Link } from 'react-router-dom';
//import CategoryList from './CategoryList'
import axios from 'axios';


export default class Demo2 extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			persons: [],
		mydata:[],
		password : '',
		email : '',
		job : ''
		};
	}
	
	
	updateApiData(){
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_login', {
			password: this.state.password,
			email: this.state.email,
			job : this.state.job
		})
		.then(function (response) {
			console.log(response);
		})
		.catch(function (error) {
			//console.log(error);
		});
	}
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		//console.log(this.state);
	}
	
	componentDidMount() {
		axios.get(`https://reqres.in/api/users`)
		.then(res => {
			this.setState({ persons : res.data.data });
		})
		
		
		
		axios.patch('https://reqres.in/api/users/2', {
			name: 'Arman',
			lastName: 'Sharma',
			job : 'web designer'
		})
		.then(function (response) {
			//console.log(response);
		})
		.catch(function (error) {
			//console.log(error);
		});
	}

	render(){
		return(
		<>
		<h2>Demo</h2>
		<table className="table table-bordered">
        { 
			this.state.persons.map( person => 
				<tr >
					<td>{person.id}</td>
					<td>{person.email}</td>
					<td>{person.first_name}</td>
					<td>{person.last_name}</td>
					<td><img src={person.avatar} alt=""/></td>
				</tr>	
			)
			
		}
		</table>
		<input type="text" name="email" value={this.state.email} className="input-text" placeholder="Enter your email" onChange={ this.handleChange.bind(this) }/>
		<input type="text" name="password" value={this.state.password} className="input-text" placeholder="Enter your password" onChange={ this.handleChange.bind(this) }/>
		<button className="" onClick={ this.updateApiData.bind(this) } >Login</button>
		</>
		);
	}
	
	
}
