import React from "react";
import { Link } from 'react-router-dom';
import "./Login_form.css";	

import login from '../images/login.png'
import user from '../images/user.png'
import lock from '../images/lock.png'
import axios from 'axios';


export default class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			remember_check : '',
			errorlogin : []
		};
	}
	
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		//console.log(this.state);
	}


	proceedToLogin(){
		var self = this;
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_login', {
			email: this.state.email,
			password: this.state.password,
		})
		.then(function (res) {
			//console.log("Login API Response");
			console.log(res);
			if( res.status === 200 && res.data.success === 1  ) {
				localStorage.setItem('_token', res.data.data.login_token);
				localStorage.setItem('name', res.data.data.name);
				localStorage.setItem('userid', res.data.data.user_id);
				self.setState({ errorlogin : "" });
				window.location.href="/dashboard";
			} else {
				self.setState({ errorlogin : res.data.message });	
			}
			
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	goTosignup(){
		window.location.href="/Signup";	
	}

	render(){
		return(
			<section className="App-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="logo-image">
							<h6>Poster <br/> Maker</h6>
						</div>
					</div>
				</div>
				<div className="row column-reverse">	
					<div className="col-md-6">
						<div className="log-sign_inner">

				<div className="login-form">
					<h1> Design Anything Publish anywhere</h1>
					<p> Create an account, it’s free. Logo is loved by beginners and expects, terms and individuals</p>
					{this.state.errorlogin != "" ? (
						<div class="alert alert-danger"> 
						
						<h6>{ this.state.errorlogin}</h6>
						
						</div>
						) : (
						""
					)}

					<span className="input_user">
						<input type="email" name="email" value={this.state.email} className="input-text" placeholder="Enter your username" onChange={ this.handleChange.bind(this) }/>
						<img src={user} className="user_img" alt=""/>
					</span>
					<span className="input_user">
						<input type="Password" name="password" value={this.state.password} className="input-pass" placeholder="Enter your password" onChange={ this.handleChange.bind(this) } />
						<img src={lock} className="lock_img" alt=""/>
					</span>
					<span className="input_user forgot_user">
						<input type="checkbox" id="Log_checkbox" name="remember_check" onChange={ this.handleChange.bind(this) }/>
						<label for="Log_checkbox">Remember Me</label>
						{/*// <p className="remember">Remember Me</p>*/}
					</span>
					<Link className="forgetP" to="">Forget password?</Link> 

					
					<div className="log-butns">
					
						<button className="login_btn" onClick={this.proceedToLogin.bind(this)} >Login</button>
						<button className="signup_btn" onClick={this.goTosignup.bind(this)} >Signup</button>
						
						{/*<Link className="signup_btn" to="/Signup">Signup</Link>*/}
					</div>
				</div>
				</div>
					</div>
					<div className="col-md-6">
						<div className="Log-sign_image">
							<img src={login} alt=""/>
						</div>
					</div>
				</div>
			</div>
		</section>

		);
	}
	
	
}