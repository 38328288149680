import React from "react";
//import ReactDom from "react-dom";



import "./main.css";
import "./dashboard.css";
//import "./assets/scripts/main.js";
//import "./assets/scripts/main.js";

import { Link } from 'react-router-dom';
import Alldesign from '../images/Alldesign.png'
//import home from '../images/home.png'
import homeBlue from '../images/homeBlue.png'
import Templates from '../images/Templates.png'
import Photos from '../images/Photos.png'
import kit from '../images/kit.png'
import team from '../images/team.png'
import school from '../images/school.png'
import $ from 'jquery';

export default class AppSidebar extends React.Component {

	   constructor(props) {
        super(props);
        this.state = {};
    }
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	 
	render(){
		return(


                <div className="app-sidebar sidebar-shadow sidebar">
                    <div className="app-header__logo">
                        <div className="logo-src"></div>
                        <div className="header__pane ml-auto">
                            <div>
                                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-className="closed-sidebar">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="app-header__mobile-menu">
                        <div>
                            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <span>
                            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"></i>
                                </span>
                            </button>
                        </span>
                    </div>    
                    <div className="scrollbar-sidebar " id="sidebar">
                        <div className="app-sidebar__inner">
                            <ul className="vertical-nav-menu">
                                <li>
                                    <Link to="#" className="mm-active">
                                        {/*<img src={home} alt=""/>*/}
                                        <img src={homeBlue} className="bli" alt=""/>
                                       <span className="sidebar_menus">
                                        Home
                                        </span>
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="">
                                        <img src={Alldesign} alt=""/>
                                        <span className="sidebar_menus">
                                        All Your Designs
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="">
                                        <img src={Templates} alt=""/>
                                        <span className="sidebar_menus">
                                        	Templates
                                        </span>
                                        
                                    </Link>
                                </li>
                                <li  >
                                    <Link to="">
                                        <img src={Photos} alt=""/>
                                        <span className="sidebar_menus">
                                        	photos
                                        </span>
                                        
                                    </Link>
                                </li>
                                <li>
                                    <Link to="">
                                        <img src={kit} alt=""/>
                                        <span className="sidebar_menus">
                                        	Brand Kit
                                        </span>
                                        
                                    </Link>
                                </li>
                                <li>
                                    <Link to="">
                                        <img src={school} alt=""/>
                                        <span className="sidebar_menus">
                                        	Design scool
                                        </span>
                                      	
                                    </Link>
                                </li>
                                <li>
                                    <Link to="">
                                        <img src={team} alt=""/>
                                        <span className="sidebar_menus">
                                        	create Team
                                        </span>
                                    </Link>
                                </li>
                               
                               
                            </ul>
                        </div>
                    </div>
                </div>    
	
	



		);
	}
	
	
}
