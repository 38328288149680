import React from "react";
//import ReactDom from "react-dom";


import "./Getstarted.css";
import { Link } from 'react-router-dom';
import getstartedPersons1 from '../images/getstartedPersons1.png'
//import CategoryList from './CategoryList'

export default class Getstarted extends React.Component {
	
	render(){
		return(
		<section className="getstarted-section">
			<div className="getstarted-section_wrap">
			<div className="container">
				<div className="row">	
					<div className="col-md-5">
						<div className="Get_title-inner">
							<div className="Get_heading">
								<h1> <span className="letter_spacing">Start Your </span> < br/> First Design </h1>
							</div>
							
							<div className="getstarted-btn">
								<Link className="go__butn" to="/Dashboard">Let's Go!</Link>
							</div>
						</div>
					</div>
					<div className="col-md-7">
						<div className="started-image">
							<img src={getstartedPersons1} alt=""/>  
						</div>
					</div>
				</div>
			</div>
			</div>
		</section>
		);
	}
	
	
}
