import React from "react";
//import ReactDom from "react-dom";


import "./Invitefriend.css";
//import { Link } from 'react-router-dom';
import friends1 from '../images/friends1.png'
//import CategoryList from './CategoryList'
import axios from 'axios';


export default class Invitefriend extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			organization_size : '',
			friends: [{ email: "" }, { email: "" }],
			errorlogin : ""
		};
	}
	
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		//console.log(this.state);
	}
	updateUser(e){
		e.preventDefault();
		var self = this;
		var token = localStorage.getItem('_token');
		//alert( token );
		const options = {
				headers:{
					'token': token,
					'content-type': 'application/json',
					}
			};
		
		var userid = localStorage.getItem('userid');
		var name = localStorage.getItem('name'); 
		if( self.state.organization_size === "" || self.state.organization_size === undefined) {
			alert("Please Select Your Organization Size");
			return false;
		}
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_update', {
			organisation_size: self.state.organization_size,
		}, options) 
		.then(function (res) {
			if( res.status === 200 && res.data.success === 1  ) {
				self.setState({ errorlogin : "" });
				window.location.href="/Getstarted";
			} else {
				self.setState({ errorlogin : res.data.message });	
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
		
		
        	
	handleInvitefiendsmailChange = idx => evt => {
		const newShareholders = this.state.friends.map((friend, sidx) => {
		  if (idx !== sidx) return friend;
		  return { ...friend, email: evt.target.value };
		});

		this.setState({ friends: newShareholders });
	};
	handleSubmit = evt => {
		evt.preventDefault();
		var self = this;
		if( this.state.organization_size === "" || this.state.organization_size === undefined) {
			alert("Please Select Your Organization Size");
			return false;
		}
		
		const { email, friends } = this.state;
		//console.log(friends);
		const invite_emails  = [];
		this.state.friends.map((friend, sidx) => {
			if( friend.email !=='' && friend.email !== undefined && friend.email !== null ) {
				invite_emails.push(friend.email);
			}
		});
		
		if(invite_emails.length < 1 ) {
			alert("Please Enter Your Friend's email address");
			return false;
		}
		
		//console.log(invite_emails);
		var userid = localStorage.getItem('userid');
		var token = localStorage.getItem('_token');
		const options = {
				headers:{
					'token': token,
					'content-type': 'application/json',
					}
			};
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_update', {
			organisation_size: self.state.organization_size,
			member_emails:  JSON.stringify(invite_emails),
		}, options)
		.then(function (res) {
			if( res.status === 200 && res.data.success === 1  ) {
				self.setState({ errorlogin : "" });
				window.location.href="/Getstarted";
			} else {
				self.setState({ errorlogin : res.data.message });	
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
		
		
		
	};
	handleAddEmail = () => {
		this.setState({
		  friends: this.state.friends.concat([{ email: "" }])
		});
	};

	handleRemoveFriendsmail = idx => () => {
		this.setState({
		  friends: this.state.friends.filter((s, sidx) => idx !== sidx)
		});
	};


	render(){
		return(
		<section className="invite-section">
			<div className="invite-section_wrap">
			<div className="container">
				<div className="row">	
					<div className="col-md-6">
						<div className="invite_title-inner">
							<div className="invite_heading">
								<h1>Invite your team to < br/> collaborate on designs</h1>
							</div>
							<form method="post" className="" onSubmit={this.handleSubmit} >
								<div className="invite_selector">
									<h6>Tell us your organization size</h6>
									{this.state.errorlogin !== "" ? (
										<div class="alert alert-danger"> 
										<ul>
											<li>{this.state.errorlogin}</li>
										</ul>
										</div>
									) : (
									""
									)}
									<select  name="organization_size" value={this.state.organization_size} className="orga_selector" onChange={ this.handleChange.bind(this) }>
									  <option value="">Choosee your organization size</option>
									  <option value="11 - 20">11 - 20</option>
									  <option value="saab">20 - 50</option>
									  <option value="mercedes">50 - 75</option>
									  <option value="audi">75 - 100</option>
									</select>
								</div>
								<div className="invite_mail">
									<h6>Enter your Invitees emails</h6>
									
										{this.state.friends.map((friend, idx) => (
										  <div className="invite_mail-inner">
											
											<input type="email" className="invite_email" placeholder={`example@gmail.com ${idx + 1}`}  value={friend.email}  onChange={this.handleInvitefiendsmailChange(idx)}  />
										  </div>
										))}
										<button type="button" onClick={this.handleAddEmail}  className="small invite_more-btn">
										  + Invite more people
										</button>
									
										<div className="invite_form-btn">
											<button type="submit"  className="invite__butn"  >Send Free Invites</button>
											<button className="sign_next" onClick={this.updateUser.bind(this)} >Next</button> 
										</div>	
								</div>
							</form>		
									

							
						</div>
					</div>
					<div className="col-md-6">
							<div className="invite-image">
								<img src={friends1} alt=""/>
							</div>
					</div>
				</div>
			</div>
			</div>
		</section>
		);
	}
	
	
}


