import React from "react";
//import ReactDom from "react-dom";



import "./main.css";
import "./dashboard.css";
//import "./assets/scripts/main.js";
//import "./assets/scripts/main.js";
//import { Link } from 'react-router-dom';

import poster1 from '../images/poster1.png'
import $ from 'jquery';

export default class AppInner extends React.Component {

	   constructor(props) {
        super(props);
        this.state = {};
    }
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	 
	render(){
		return(


                    <div className="app-main__inner">  
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-slider">
                                        <h3>Create A Design</h3>
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Poster</h5>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Facebook cover</h5>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Flayer</h5>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Banner</h5>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Banner</h5>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="slider_img">
                                        <img src={poster1} alt=""/>
                                    </div>
                                    <div className="slider_text">
                                        <h5>Banner</h5>
                                    </div>
                                </div>
                            </div>
                    </div>
          
	



		);
	}
	
	
}
