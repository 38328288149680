import React from "react";
//import ReactDom from "react-dom";



import "./main.css";
import "./Createdesign.css";
//import "./assets/scripts/main.js";
//import "./assets/scripts/main.js";
import { Link } from 'react-router-dom';

import left_arrow from '../images/left_arrow.png'
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppInner from './AppInner';
import Alldesign from '../images/Alldesign.png'
import homeBlue from '../images/homeBlue.png'
import Templates from '../images/Templates.png'
import Photos from '../images/Photos.png'
import kit from '../images/kit.png'
import team from '../images/team.png'
import school from '../images/school.png'
import $ from 'jquery';

export default class CreateDesign extends React.Component {

	   constructor(props) {
        super(props);
        this.state = {};
    }
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	 
	render(){
		return(

<section className="dash_inner">
	<div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
        <div className="app-header header-shadow">
            
            <div class="app-header__content">
                <div class="app-header-left"></div>
                <div class="app-header-right">
                    <div className="poster-title">  Untitled Poster </div>
                    <div class="header-btn-lg pr-0">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left  mr-3 header-user-info">
                                    <div class="widget-heading">Print Poster</div>
                                    </div>
                                    <div class="widget-content-left mr-4">
                                    <div class="btn-group">
                                        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn" href="">
                                            <img width="42" class="rounded-circle" src="assets/images/avatars/1.jpg" alt="" /><i class="fa fa-angle-down ml-2 opacity-8"></i>
                                        </a>
                                        <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" >
                                            <button type="button" tabindex="0" class="dropdown-item">User Account</button>
                                            <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                                            <a href=""><h6 tabindex="-1" class="dropdown-header">Log Out</h6></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content-right header-user-info ml-3 Setting_opt">
                                    <button type="button" class="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example">
                                        <img src="" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        
            <div className="app-main">
                <div className="app-sidebar sidebar-shadow sidebar">
                    <div className="scrollbar-sidebar" id="sidebar">
                        <div className="app-sidebar__inner">
                            <div class="app-header__logo sidebar">
                                <div class="header__pane ml-auto">
                                    <div>
                                        <button type="button" onClick={() => this.toggleButton()} class="hamburger close-sidebar-btn hamburger--elastic" data-classname="closed-sidebar">
                                            <span class="hamburger-box">
                                                <img src={left_arrow} className="bli" alt=""/>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div  class="col-md-12">
                                <h3>Left Tabs</h3>
                                <hr/>
                                <div class="row">
                                <div class="col-md-4"> 
                             
                                  <ul class="nav nav-tabs tabs-left sideways">
                                    <li class="active"><a href="#home-v" data-toggle="tab">Home</a></li>
                                    <li><a href="#profile-v" data-toggle="tab">Profile</a></li>
                                    <li><a href="#messages-v" data-toggle="tab">Messages</a></li>
                                    <li><a href="#settings-v" data-toggle="tab">Settings</a></li>
                                  </ul>
                                </div>

                                <div class="col-md-8">
                                 
                                  <div class="tab-content">
                                    <div class="tab-pane active" id="home-v">Home Tab.</div>
                                    <div class="tab-pane" id="profile-v">Profile Tab.</div>
                                    <div class="tab-pane" id="messages-v">Messages Tab.</div>
                                    <div class="tab-pane" id="settings-v">Settings Tab.</div>
                                  </div>
                                </div>
                                </div>
                                <div class="clearfix"></div>

                            </div>

                            {/*// <div className="container">
                            // <div className="row">
                            //     <div className="col-md-4 sidebar-inner_left">
                            //         <ul className="vertical-nav-menu">
                            //             <li>
                            //                 <Link to="#" className="mm-active">
                            //                     <img src={home} alt=""/>
                            //                     <img src={homeBlue} className="bli" alt=""/>
                            //                    <p className="sidebar_menus">
                            //                     Home
                            //                     </p>
                            //                 </Link>
                            //             </li>
                                        
                            //             <li>
                            //                 <Link to="">
                            //                     <img src={Alldesign} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                     All Designs
                            //                     </p>
                            //                 </Link>
                            //             </li>
                            //             <li>
                            //                 <Link to="">
                            //                     <img src={Templates} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                         Templates
                            //                     </p>
                                                
                            //                 </Link>
                            //             </li>
                            //             <li  >
                            //                 <Link to="">
                            //                     <img src={Photos} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                         photos
                            //                     </p>
                                                
                            //                 </Link>
                            //             </li>
                            //             <li>
                            //                 <Link to="">
                            //                     <img src={kit} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                         Brand Kit
                            //                     </p>
                                                
                            //                 </Link>
                            //             </li>
                            //             <li>
                            //                 <Link to="">
                            //                     <img src={school} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                         Design scool
                            //                     </p>
                                                
                            //                 </Link>
                            //             </li>
                            //             <li>
                            //                 <Link to="">
                            //                     <img src={team} alt=""/>
                            //                     <p className="sidebar_menus">
                            //                         create Team
                            //                     </p>
                            //                 </Link>
                            //             </li>
                            //         </ul>
                            //     </div>

                            //     <div className="col-md-8 sidebar-inner_right">
                            //         <h6>Recently Used</h6>
                            //     </div>
                            // </div>
                            // </div>*/}
                        </div>
                    </div>
                </div>
              
       
                <div className="app-main__outer sidebar">
                	<div className="header_pane">
                            {/*//<div class="input-group">
							// 	<span class="input-group-btn"><button type="button" className="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button></span>
							// 	<input type="text" value="" className="form-control" placeholder="Search dashboard..."/>
							// </div>*/}
                    </div>
                    <AppInner></AppInner>
                    
               </div>
            </div>
    </div>

</section>			
	



		);
	}
	
	
}
