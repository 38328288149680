import React from "react";
//import ReactDom from "react-dom";


import "./Getstarted.css";
//import { Link } from 'react-router-dom';
import axios from 'axios';

		

export default class Demo extends React.Component {
	
		constructor(props) {
			super(props);
			this.state = {
				user: [],
			myuser:[],
			name : '',
			password : '',
			email : '',
			category : '',
			member_emails : '',
			pass : ''
			//organization_size : '',
			
			};
			 this.handleChange = this.handleChange.bind(this);
		}
 		
 		updateApiData(){
			// axios.post('https://postermakerapi.iapplabz.co.in/api/user_registration', {
			// 	name : this.state.name,
			// 	password: this.state.password,
			// 	email : this.state.email,
			// 	category : this.state.category,
			// 	organization_size : this.state.organization_size,
			// })
			// .then(function (response) {
			// 	console.log(response);
			// })
			// .catch(function (error) {
			// 	//console.log(error);
			// });
		}
		onAddItem(){
			// alert("asdfa");
			var final_array={};
			final_array.member_emails=this.state.member_emails;
			final_array.pass=this.state.pass;
			// var data=JSON.stringify(final_array)
			// console.log();
			axios.post('https://postermakerapi.iapplabz.co.in/api/user_update/27', final_array)

			
		}
		handleChange(e) {
		this.setState({[e.target.name]: e.target.value });

			// this.setState((state) => { return {[e.target.name]: e.target.value} });
			console.log(e.target.name);
		}
			// Make a request for a user with a given ID
		componentDidMount() {	
			// axios.get('https://jsonplaceholder.typicode.com/todos/6')
			//   .then(res => {
			// 	const user = res.data;
			// 	console.log( user );
			// 	this.setState({ user });
			// 	})    
			// axios.get('https://postermakerapi.iapplabz.co.in/api/get_users/27')
			//   .then(res => {
			// 	const myuser = res.data.data;
			// 	console.log( myuser );
			// 	this.setState({ myuser });
			// 	})
			// axios.post('https://postermakerapi.iapplabz.co.in/api/user_update/27' {
			// 	profession_category : 'hocky',
	
			// 	})
			// 	.then(function (response) {
			// 		console.log(response);
			// 	})
			// 	.catch(function (error) {
			// 			console.log(error);
			// 	});
		

		}
		
		
		render(){
		return(
			<>
				<div>

					<p>{this.state.user.userId}</p>
					<p>{this.state.user.id}</p>
					<p>{this.state.user.title}</p>
				
				</div>
				<table className="table table-bordered">
					<tr >
						<td>{this.state.myuser.name}</td>
						<td>{this.state.myuser.email}</td>
						<td>{this.state.myuser.profession_category}</td>
						<td>{this.state.myuser.organisation_size}</td>
						
					</tr>	
					
					
				</table>
				{/* // <div className="">
				// 	<input type="text" name="email" value={this.state.email} className="input-text" placeholder="Enter your email" onChange={ this.handleChange.bind(this) }/>
				// 	<input type="password" name="password" value={this.state.password} className="input-text" placeholder="Enter your password" onChange={ this.handleChange.bind(this) }/>
					
				// 	<button className="" onClick={ this.updateApiData.bind(this) } >Login</button>
				// </div>
				// <div className="">
				// 	<input type="text" name="name" value={this.state.name} className="input-text" placeholder="Enter your user name" onChange={ this.handleChange.bind(this) }/>
				// 	<input type="password" name="password" value={this.state.password} className="input-text" placeholder="Enter your password" onChange={ this.handleChange.bind(this) }/>
				// 	<input type="text" name="email" value={this.state.email} className="input-text" placeholder="Enter your email" onChange={ this.handleChange.bind(this) }/>
				// 	<input type="text" name="profession_category" value={this.state.profession_category} className="input-text" placeholder="Enter your category" onChange={ this.handleChange.bind(this) }/>
				// 	<input type="text" name="organization_size" value={this.state.organization_size} className="input-text" placeholder="Enter your organization_size" onChange={ this.handleChange.bind(this) }/>
				// 	<button className="" onClick={ this.updateApiData.bind(this) } >Login</button>
				// </div>*/}


				 <div>
			        <input  type="text"  name="member_emails" value={this.state.member_emails}  onChange={this.handleChange}/>
			        <button  type="button"  onClick={this.onAddItem.bind(this)} >Add</button>
      			</div>
			</>
		);
	}
	
	
}


