import React from "react";
//import ReactDom from "react-dom";
import axios from 'axios';


import teacher6 from '../images/teacher6.png'
import student1 from '../images/student1.png'
import personal1 from '../images/personal1.png'
import smallBusiness1 from '../images/smallBusiness1.png'
import LargeBusiness1 from '../images/LargeBusiness1.png'
import charity1 from '../images/charity1.png'
//import { Link } from 'react-router-dom';
//import SignupForm from './SignupForm.js'

export default class CategoryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category : '',
			errorlogin : ''
		};
		this.updateCategory = this.updateCategory.bind(this);
	} 
	
	updateCategory(category){
		var Token = localStorage.getItem('_token');
		
			const options = {
				headers:{
					'token': Token,
					'content-type': 'application/json',
					}
			};
	
	
		var self = this;
		var userid = localStorage.getItem('userid');
		var name = localStorage.getItem('name');
		
		axios.post('https://postermakerapi.iapplabz.co.in/api/user_update', {
			profession_category: category,
		}, options) 
		.then(function (res) {
			if( res.status === 200 && res.data.success === 1  ) {

				//localStorage.setItem('name', res.data.data.name);
				//localStorage.setItem('userid', res.data.data.user_id);
				self.setState({ errorlogin : "" });
				window.location.href="/Invitefriend";
			} else {
				self.setState({ errorlogin : res.data.message });	
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	render(){
		return(
						<div className="category_title-wrap">
							<div className="row">
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Teacher')}>
										<div className="category_image">
											<img src={teacher6} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Teacher</h3>
											<p>You’re here to empower you students</p>
										</div>
									</a>
								</div>
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Student')}>
										<div className="category_image">
											<img src={student1} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Student</h3>
											<p>You’re here to Impress Your teacher and class mates</p>
										</div>
									</a>
								</div>
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Personal')}>
										<div className="category_image">
											<img src={personal1} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Personal</h3>
											<p>You’re here to make anything and everything</p>
										</div>
									</a>
								</div>
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Small Bussiness')}>
										<div className="category_image">
											<img src={smallBusiness1} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Small Bussiness</h3>
											<p>You’re here to empower you students</p>
										</div>
									</a>
								</div>
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Large Bussiness')}>
										<div className="category_image">
											<img src={LargeBusiness1} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Large Bussiness</h3>
											<p>You’re here to empower you students</p>
										</div>
									</a>
								</div>
								<div className="col-md-4">
									<a href="javascript:void(0)" onClick={() => this.updateCategory('Charity')}>
										<div className="category_image">
											<img src={charity1} alt=""/>
										</div>
										<div className="category_Name">
											<h3>Charity</h3>
											<p>You’re here to empower you students</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					
		);
	}
	
	
}