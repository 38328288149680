import React from "react";
//import ReactDom from "react-dom";



import "./main.css";
import "./dashboard.css";
//import "./assets/scripts/main.js";
//import "./assets/scripts/main.js";
//import { Link } from 'react-router-dom';


import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppInner from './AppInner';
import $ from 'jquery';

export default class Dashboard extends React.Component {

	   constructor(props) {
        super(props);
        this.state = {};
    }
  
    toggleButton() {
      $('.sidebar').toggleClass('active')
    }
	 
	render(){
		return(

<section className="dash_inner">
	<div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
        <AppHeader></AppHeader>

        
            <div className="app-main">
               <AppSidebar></AppSidebar>
       
                <div className="app-main__outer sidebar">
                	<div className="header_pane">
                            {/*//<div class="input-group">
							// 	<span class="input-group-btn"><button type="button" className="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button></span>
							// 	<input type="text" value="" className="form-control" placeholder="Search dashboard..."/>
							// </div>*/}
                    </div>
                    <AppInner></AppInner>
                     <div className="app_footer">
                        <div className="row">
                            <div className="col-md-6 footer_left"><p>© 2019 posterapplab All Rights Reserved.</p></div>
                            <div className="col-md-6 footer_right"> <p>Contact us on: <a href="mailto:appledev650@posterapplab.com">appledev650@posterapplab.com </a></p></div>
                        </div>
                   </div>
               </div>

            </div>
    </div>

</section>			
	



		);
	}
	
	
}
