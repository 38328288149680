import React from "react";
//import ReactDom from "react-dom";
//import Container from "react-bootstrap/Container";
//import Button from "react-bootstrap/Button";


import "./Category.css";
//import teacher3 from '../images/teacher3.png'
import CategoryList from './CategoryList'

export default class Category extends React.Component {
	render(){
		return(
		<section className="Category-section">
			<div className="Category-section_wrap">
			<div className="container">
				
				<div className="row">	
					<div className="col-md-4">
						<div className="category_title-inner">
							<h1>Welcome To <br/>Poster Maker</h1>
							<p>Can you tell us what you'll be using poster maker for? we'll use this to recommend design types and templates espacially for you</p>
						</div>
					</div>
					<div className="col-md-8">
						<CategoryList></CategoryList>
					</div>
				</div>
			</div>
			</div>
		</section>
		);
	}
	
	
}